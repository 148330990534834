import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Contact from '../components/Contact';

const ContactPage = () => {
  const {
    datoCmsContact: { seoMetaTags },
    datoCmsContactInformation,
  } = useStaticQuery(graphql`
    query ContactPageQuery {
      datoCmsContact {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
      datoCmsContactInformation {
        address
        email
        phone
      }
    }
  `);

  return (
    <Layout seo={seoMetaTags}>
      <main>
        <Contact contactDetails={datoCmsContactInformation} />
      </main>
    </Layout>
  );
};

export default ContactPage;
