import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container } from './ui';
import ContactMap from './ContactMap';
import ContactForm from './ContactForm';

const StyledContact = styled.section`
  ${sectionMargins()}
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 100px;
  `}
`;

const Contact = ({ contactDetails }) => (
  <StyledContact>
    <Container>
      <StyledInner>
        <ContactMap contactDetails={contactDetails} />
        <ContactForm />
      </StyledInner>
    </Container>
  </StyledContact>
);

export default Contact;
