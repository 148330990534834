import React from 'react';
import styled from 'styled-components';
import {
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  standardColours,
} from '../styles';
import { Svg } from './ui';
import mapIcon from '../images/map-icon.inline.svg';
import letterIcon from '../images/letter-icon.inline.svg';
import phoneIcon from '../images/phone-icon.inline.svg';

const StyledContactMap = styled.div``;

const StyledContact = styled.div``;

const StyledHeading = styled.h1`
  ${fluidFontSize(
    '20px',
    '36px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-weight: ${fontWeights.regular};
  font-family: ${brandFonts.secondary};
  color: ${standardColours.black};
`;

const StyledContactItems = styled.div`
  display: grid;
  row-gap: 35px;
  margin-top: 30px;
`;

const StyledContactItem = styled.div`
  display: flex;
  row-gap: 35px;
`;

const StyledIcon = styled(Svg)`
  width: 32px;
  height: 32px;
  margin-right: 25px;
`;

const StyledText = styled.p`
  ${fontSize(14)};
  white-space: break-spaces;
  line-height: 2;

  ${minBreakpointQuery.mlarge`
    ${fontSize(18)};
  `}
`;

const StyledMap = styled.iframe`
  height: 275px;
  max-width: 100%;
  margin-top: 30px;

  ${minBreakpointQuery.mlarge`
    height: 350px;
    margin-top: 40px;
  `}

  ${minBreakpointQuery.large`
    height: 400px;
    margin-top: 50px;
  `}

  ${minBreakpointQuery.xlarge`
    height: 450px;
  `}
`;

const ContactMap = ({ contactDetails: { address, email, phone } }) => (
  <StyledContactMap>
    <StyledContact>
      <StyledHeading>Contact Us</StyledHeading>
      <StyledContactItems>
        <StyledContactItem>
          <StyledIcon image={mapIcon} />
          <StyledText>{address}</StyledText>
        </StyledContactItem>
        <StyledContactItem>
          <StyledIcon image={letterIcon} />
          <StyledText>{email}</StyledText>
        </StyledContactItem>
        <StyledContactItem>
          <StyledIcon image={phoneIcon} />
          <StyledText>{phone}</StyledText>
        </StyledContactItem>
      </StyledContactItems>
    </StyledContact>
    <StyledMap
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2377.959627460158!2d-2.99006004883532!3d53.41554817712881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b2137a7dcc3a5%3A0x3b79e697aaf4c214!2sDownland!5e0!3m2!1sen!2suk!4v1644942300188!5m2!1sen!2suk"
      width="870"
      height="450"
      style={{ border: 0 }}
      loading="lazy"
      title="Downland Bedding Google Map"
    ></StyledMap>
  </StyledContactMap>
);

export default ContactMap;
