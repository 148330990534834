import React from 'react';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  fluidFontSize,
  formButtonStyles,
  formGridStyles,
  inputStyles,
  labelStyles,
  maxBreakpointQuery,
  minBreakpointQuery,
  selectStyles,
  selectWrapStyles,
  standardColours,
  textAreaStyles,
  visuallyHidden,
} from '../styles';
import { Heading } from './ui';

const StyledContactForm = styled.div`
  background-color: ${standardColours.lightGrey};

  ${maxBreakpointQuery.medium`
    margin-top: 30px;
  `};
`;

const StyledInner = styled.div`
  padding: 35px 30px;

  ${minBreakpointQuery.medium`
    padding: 45px 30px;
  `}

  ${minBreakpointQuery.mlarge`
    padding: 55px 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 70px 50px;
  `}

  ${minBreakpointQuery.xlarge`
    padding: 80px 60px;
  `}
`;

const StyledHeading = styled(Heading)`
  ${fluidFontSize(
    '20px',
    '36px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  text-align: center;
`;

const StyledForm = styled.form`
  ${formGridStyles()}
`;

const StyledLabel = styled.label`
  ${labelStyles()}

  ${({ honeypot }) => {
    if (honeypot) {
      return css`
        ${visuallyHidden()}
      `;
    }
  }}
`;

const StyledInput = styled.input`
  ${inputStyles()}
`;

const StyledSelectWrap = styled.div`
  ${selectWrapStyles()};
`;

const StyledSelect = styled.select`
  ${selectStyles()};
`;

const StyledTextarea = styled.textarea`
  ${textAreaStyles()}
`;

const StyledSubmit = styled.button`
  ${formButtonStyles()}
`;

const ContactForm = () => (
  <StyledContactForm>
    <StyledInner>
      <StyledHeading>Make An Enquiry</StyledHeading>
      <StyledForm
        id="contact-form"
        name="contact-form"
        method="POST"
        action="/thanks/"
        netlify-honeypot="question"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <StyledLabel honeypot={true}>
          <StyledInput type="text" name="question" />
        </StyledLabel>
        <StyledLabel>
          <StyledInput type="text" name="name" placeholder="Name" required />
        </StyledLabel>
        <StyledLabel>
          <StyledInput type="email" name="email" placeholder="Email" required />
        </StyledLabel>
        <StyledLabel>
          <StyledInput type="tel" name="phone" placeholder="Phone" required />
        </StyledLabel>
        <StyledLabel>
          <StyledSelectWrap>
            <StyledSelect name="enquiry-type" required>
              <option value="" selected disabled>
                Type of enquiry
              </option>
              <option value="general-enquiry">General enquiry</option>
              <option value="bulk-order-enquiry">Bulk order enquiry</option>
              <option value="customer-support">Customer support</option>
              <option value="other">Other</option>
            </StyledSelect>
          </StyledSelectWrap>
        </StyledLabel>
        <StyledLabel>
          <StyledTextarea name="message" placeholder="Message" required />
        </StyledLabel>
        <StyledSubmit type="submit">Send</StyledSubmit>
      </StyledForm>
    </StyledInner>
  </StyledContactForm>
);

export default ContactForm;
